<template>
    <div class="container-fluid">
        <div class="card my-3">
            <div class="card-header d-inline-flex justify-content-between">
                <AdminSideBarToggle/>
                <el-input
                    style="max-width: 250px"
                    class="ms-4"
                    clearable
                    v-model="searchText"
                    prefix-icon="el-icon-search"
                    :placeholder="$t('search')"/>
            </div>
            <!-- Users table -->
            <div class="card-body">
                <el-table
                    v-loading="usersLoadingState"
                    :data="filteredUsers"
                    style="width: 100%">
                    <el-table-column
                        prop="email"
                        fixed="left"
                        min-width="150px"
                        label="Email">
                    </el-table-column>
                    <el-table-column
                        min-width="160px"
                        :formatter="roleFormatter"
                        prop="role"
                        :label="$t('role')">
                    </el-table-column>
                    <el-table-column
                        min-width="160px"
                        :formatter="dateTimeFormatter"
                        prop="created_at"
                        :label="$t('createdAt')">
                    </el-table-column>
                    <el-table-column
                        min-width="140px"
                        :label="$t('actions')">
                        <template slot-scope="scope">
                            <el-button
                                size="small"
                                type="primary"
                                @click="showUpdateModal(scope.row)">{{ $t('edit') }}</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <!-- User edit dialog -->
        <el-dialog
            class="dialog"
            :title="$t('userEditing')"
            :visible.sync="isUserEditDialogVisible"
            width="30%">
            <el-form ref="form" :model="userEditForm" label-width="50px">
                <el-form-item label="Email">
                    <el-input v-model="userEditForm.email" disabled></el-input>
                </el-form-item>
                <el-form-item :label="$t('role')">
                    <el-radio-group v-model="userEditForm.role">
                        <el-radio :label="0">{{ $t('user') }}</el-radio>
                        <el-radio :label="1">{{ $t('admin') }}</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="isUserEditDialogVisible = false">{{ $t('close') }}</el-button>
                <el-button size="small" type="primary" @click="updateUser" :loading="usersLoadingState">{{ $t('save') }}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {dateTimeToString} from '@/common/helpers'
import AdminSideBarToggle from '@/components/AdminSideBarToggle'

export default {
    name: 'Users',
    components: {
        AdminSideBarToggle,
    },
    i18n: {
        messages: {
            en: {
                users: 'Users',
                search: 'Search',
                userEditing: 'User editing',
                admin: 'Admin',
                user: 'User',
                role: 'Role',
                createdAt: 'Created at',
                actions: 'Actions',
                edit: 'Edit',
                error: 'Error',
                errorLoadingUsers: 'Error loading users',
                save: 'Save',
                close: 'Close',
                success: 'Success',
                userUpdated: 'User updated',
                userUpdateError: 'User update error',
            },
            ru: {
                users: 'Пользователи',
                search: 'Поиск',
                userEditing: 'Редактирование пользователя',
                admin: 'Администратор',
                user: 'Пользователь',
                role: 'Роль',
                createdAt: 'Дата создания',
                actions: 'Действия',
                edit: 'Изменить',
                error: 'Ошибка',
                errorLoadingUsers: 'Ошибка загрузки прользователей',
                save: 'Сохранить',
                close: 'Закрыть',
                success: 'Успешно',
                userUpdated: 'Пользователь обновлен',
                userUpdateError: 'Ошибка обновления пользователя'
            },
        }
    },
    data: function () {
        return {
            isUserEditDialogVisible: false,
            userEditForm: {},
            searchText: ''
        }
    },
    computed: {
        users: function () {
            return this.$store.getters['admin/users']
        },
        filteredUsers: function () {
            return this.users.filter(user => {
                return user.email.toUpperCase().includes(this.searchText.toUpperCase())
            })
        },
        usersLoadingState: function () {
            return this.$store.getters['admin/usersLoadingState']
        }
    },
    methods: {
        roleFormatter: function (row, column, cellValue) {
            if(cellValue === 1) {
                return this.$t('admin')
            }

            return this.$t('user')
        },
        dateTimeFormatter: function (row, column, cellValue) {
            return dateTimeToString(cellValue)
        },
        showUpdateModal: function (user) {
            this.userEditForm = Object.assign({}, user)
            this.isUserEditDialogVisible = true
        },
        updateUser: function () {
            this.$store.dispatch('admin/usersUpdateRequest', this.userEditForm)
                .then(() => {
                    this.$notify({
                        type: 'success',
                        title: this.$t('success'),
                        message: this.$t('userUpdated'),
                    })
                    this.isUserEditDialogVisible = false
                })
                .catch(() => {
                    this.$notify({
                        type: 'error',
                        title: this.$t('error'),
                        message: this.$t('userUpdateError'),
                    })
                })
        },
    },
    mounted() {
        this.$store.dispatch('admin/usersGetRequest')
            .catch(() => {
                this.$notify({
                    type: 'error',
                    title: this.$t('error'),
                    message: this.$t('errorLoadingUsers'),
                })
            })
    }
}
</script>

<style scoped>
@media (max-width: 576px) {
    .dialog >>> .el-dialog {
        width: 100%!important;
    }
}
</style>
